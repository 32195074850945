import { Formik } from "formik";
import { Form, Row, Col } from "reactstrap";
import Toaster from "../../../Shared/Toaster";
import InputField from "../../../Shared/InputField";
import FillBtn from "../../../Shared/Buttons/FillBtn";
import { useDispatch, useSelector } from "react-redux";
import React, { memo, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../../../HelperMethods/LoadingScreen";
import { authChangePassword } from "../../../Redux/features/User/userApi";
import { PASSWORD_SCHEMA } from "../../../Shared/ValidationData/validation";
import { PASSWORD_INITIAL_VALUES } from "../../../Shared/ValidationData/initialValue";
import { newPassword } from "../../../Redux/features/ForgotPassword/forgotPasswordApi";
import {
  RESET_PASSWORD_URL,
  AUTH_CHANGE_PASSWORD_URL,
} from "../../../utils/constants";

const NewPasswordForm = ({ textOrange = "", setChangePasswordPopup }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, email, code } = useSelector((state) => state.forgotPassword);
  const location = useLocation();
  const otp = location.state?.otp;
  const user = useSelector((state) => state.user.user) || {};
  const [resetUserEmail, setResetUserEmail] = useState(email);

  const handleCancelClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleAuthChangePassword = async (values) => {
    const data = {
      apiEndpoint:
        process.env.REACT_APP_BACKEND_BASE_URL +
        AUTH_CHANGE_PASSWORD_URL +
        user?.id,
      requestData: JSON.stringify({
        ...values,
      }),
    };
    const res = await dispatch(authChangePassword(data));
    if (res.type === "authChangePassword/fulfilled") {
      Toaster.success(res?.payload?.message);
    }else{
      console.error("Password reset failed", res);
    }
 

    setChangePasswordPopup(false);
  };

  const handleNewPasswordSubmit = async (values) => {
    if (!otp) {
      console.error("OTP is missing");
      return;
    }

    const emailToSend = user?.email || resetUserEmail;
    const data = {
      apiEndpoint: process.env.REACT_APP_BACKEND_BASE_URL + RESET_PASSWORD_URL,
      requestData: JSON.stringify({
        ...values,
        email: emailToSend,
        code: otp,
      }),
    };
    const res = await dispatch(newPassword(data));

    if (res.type === "newPassword/fulfilled") {
      navigate("/signIn");
    } else {
      console.error("Password reset failed", res);
    }
  };

  return (
    <div className="w-100">
      {loading === "pending" && <LoadingScreen />}
      <Formik
        validationSchema={PASSWORD_SCHEMA}
        initialValues={{ ...PASSWORD_INITIAL_VALUES }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            if (otp) {
              await handleNewPasswordSubmit(values);
            } else {
              await handleAuthChangePassword(values);
            }
          } catch (error) {
            console.error("Error submitting form:", error);
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="justify-content-center">
              <Col md={10}>
                <div className="mb-3">
                  <label
                    htmlFor=""
                    className={`small fw-bold mb-1 ${textOrange}`}
                  >
                    New password
                  </label>
                  <InputField
                    type="password"
                    name="password"
                    value={values.password}
                    onBlurHandle={handleBlur}
                    onChangeHandle={handleChange}
                    placeholder="Enter new password"
                  />
                  <p className="errorField">
                    {errors.password && touched.password && errors.password}
                  </p>
                </div>
                <label
                  htmlFor=""
                  className={`small fw-bold mb-1 ${textOrange}`}
                >
                  Confirm new password
                </label>
                <InputField
                  type="password"
                  name="confirm_password"
                  onBlurHandle={handleBlur}
                  onChangeHandle={handleChange}
                  value={values.confirm_password}
                  placeholder="Enter confirm password"
                />
                <p className="errorField">
                  {errors.confirm_password &&
                    touched.confirm_password &&
                    errors.confirm_password}
                </p>
                <Row className="my-4">
                  <Col md={6} className="mb-3">
                    <FillBtn
                      type="submit"
                      className="w-100"
                      text="Save password"
                    />
                  </Col>
                  <Col md={6}>
                    <FillBtn
                      text="Cancel"
                      className="w-100"
                      handleOnClick={handleCancelClick}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo(NewPasswordForm);
