import React from "react";
import ProfileCard from "../ProfileCard";
import UpComingTibits from "../UpComingTibits";
import TimePickerTibit from "../TimePickerTibit";
import SetTibitDateCalender from "../SetTibitDateCalender";

const RightBar = () => {
  return (
    <React.Fragment>
      <div className="">
        <div className="d-md-block d-none">
         
          <ProfileCard />
        </div>
        <div className="rounded-3 overflow-hidden">
 
      <iframe
        width="100%"
        height="220"
        src="https://www.youtube.com/embed/zbj1NhH7brk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> </div>
        <div className="overflow-y-scroll d-flex  justify-content-center align-items-center" style={{ maxHeight: "93vh" }}>
          {/* <div className="my-3 d-flex w-100 justify-content-center d-none">
            <SetTibitDateCalender />
          </div>
          <div className="my-3 d-flex w-100 justify-content-center d-none">
            <TimePickerTibit />
          </div> */}
        <div className="w-100 ms-2">  <UpComingTibits /></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightBar;
